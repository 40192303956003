<template>
    <div>
        <el-row :gutter="10" style="margin:0 10px; margin-bottom: 60px;">
            <el-col :span="6">
                <el-card style="color: #409EFF">
                    <div><i class="el-icon-bank-card"/> 用户总数</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item1}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card style="color: #F56C6C">
                    <div><i class="el-icon-bank-card"/> 考研交流信息数量</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item2}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card style="color: #67C23A">
                    <div><i class="el-icon-bank-card"/> 考研信息提交数量</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item3}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card style="color: #E6A23C">
                    <div><i class="el-icon-bank-card"/> 今年毕业数量</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item4}}
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" >
                <div style="padding: 10px">
                    <el-card>
                        <div  style="  display: flex;justify-content: center;font-size: 20px">
                            历年考研统计图
                        </div>
                        <div id="main" style="width: 100%; height: 500px;"></div>
                    </el-card>
                </div>
            </el-col>

            <el-col :span="12" >
                <div style="padding: 10px">
                    <el-card>
                        <div  style="  display: flex;justify-content: center;font-size: 20px">
                            考研信息饼状图
                        </div>
                        <div id="pie" style="width: 100%; height: 500px;"></div>
                    </el-card>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import * as echarts from 'echarts'

    export default {
        name: "Home",
        data() {
            return {
                item1: 100,
                item2: 100,
                item3: 100,
                item4: 100,
            }
        },
        mounted() {  // 页面元素渲染之后再触发
            let option = {
                title: {
                    text: "",
                    x: "center",
                    y: 0,
                    textStyle: {
                        color: "#000",
                        fontSize: 20,
                        fontWeight: "normal"
                    }
                },
                tooltip: {
                    trigger: "axis",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true,
                            backgroundColor: "#7B7DDC"
                        }
                    },
                    textStyle: {
                        color: '#000' // 提示框文本颜色
                    },
                },
                legend: {
                    textStyle: {
                        color: "#000"
                    },
                    orient: 'horizontal',
                    bottom: 0,
                    padding: 0 // 四个数字分别为上右下左与原位置距离
                },
                grid: {
                    x: "8%",
                    width: "83%",
                    y: "4%"
                },
                xAxis: {
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: "#000"
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: [
                    {
                        splitLine: { show: false },
                        axisLine: {
                            lineStyle: {
                                color: "#000"
                            }
                        },

                        axisLabel: {
                            formatter: "{value} "
                        }
                    },
                    {
                        splitLine: { show: true },
                        axisLine: {
                            lineStyle: {
                                color: "#000"
                            }
                        },
                        axisLabel: {
                            formatter: "{value} %"
                        }
                    }
                ],
                series: [
                    {
                        name: "报考人数",
                        type: "bar",
                        barWidth: 20,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 5,
                                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#956FD4" },
                                    { offset: 1, color: "#3EACE5" }
                                ])
                            }
                        },
                        data: []
                    },
                    {
                        name: "录取人数",
                        type: "line",
                        smooth: true,
                        showAllSymbol: true,
                        symbol: "emptyCircle",
                        symbolSize: 8,
                        itemStyle: {
                            normal: {
                                color: "#F02FC2"
                            }
                        },
                        data: []
                    },

                    {
                        name: "录取百分比",
                        type: "line",
                        smooth: true,
                        showAllSymbol: true,
                        symbol: "emptyCircle",
                        symbolSize: 8,
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: "#4a60f0"
                            }
                        },
                        data: []
                    },

                ]
            };

            let pieoptions = {
                title: {

                    // zlevel: 0,
                    text: [
                        "{value|" + 0 + "}",
                        "{name|总数}"
                    ].join("\n"),
                    top: "center",
                    left: "center",
                    textStyle: {
                        rich: {
                            value: {
                                color: "#000",
                                fontSize: 24,
                                fontWeight: "bold",
                                lineHeight: 20,
                            },
                            name: {
                                color: "#000",
                                lineHeight: 20,
                            },
                        },
                    },
                },
                tooltip: {
                    trigger: "item",
                    backgroundColor: "rgba(0,0,0,.6)",
                    borderColor: "rgba(147, 235, 248, .8)",
                    textStyle: {
                        color: "#FFF",
                    },
                },
                legend: {
                    show: false,
                    top: "5%",
                    left: "center",
                },
                series: [
                    //展示圆点
                    {
                        name: "考研状态",
                        type: "pie",
                        radius: ["42%", "65%"],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: "rgba(0,0,0,0)",
                            borderWidth: 2,
                        },

                        color: ["#956FD4", "#3EACE5", "#4a60f0","#3EACE5", "#3EACE5", "#4a60f0",],
                        data: [],
                        tooltip: { show: true },
                        label: {
                            formatter: [
                                '   {b|{b}}   ',
                                '   {c|{c}个}   {per|{d}%}  '
                            ].join('\n'),
                            //   position: "outside",
                            rich: {
                                b: {
                                    color: "#000",
                                    fontSize: 14,
                                    lineHeight: 26,
                                },
                                c: {
                                    color: "#000",
                                    fontSize: 14,
                                },
                                per: {
                                    color: "#000",
                                    fontSize: 14,
                                },
                            },
                        },
                        labelLine: {
                            length: 20, // 第一段线 长度
                            length2: 46, // 第二段线 长度
                            show: true,

                        },
                        emphasis: {
                            show: true,
                        },
                    }
                ],
            };

            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);

            var pieDom = document.getElementById('pie');
            var pieChart = echarts.init(pieDom);

            var res = this.request.get("/echarts/members").then(res => {
                // this.item1=res.userSize
                // this.item2=res.forumSize
                // this.item3=res.postgraduateSize
                // this.item4=res.yearCount
                // var postgraduateYearList=res.postgraduateYearList
                // // 填空
                // option.xAxis.data = postgraduateYearList.map(item=>item.yearName)
                // option.series[0].data = postgraduateYearList.map(item=>item.candidate)
                // option.series[1].data = postgraduateYearList.map(item=>item.pass)
                // option.series[2].data = postgraduateYearList.map(item=>item.passOpenInt)

                this.item1=11
                this.item2=22
                this.item3=33
                this.item4=44
                // 填空
                option.xAxis.data = [2021,2022,2023,2024]
                option.series[0].data = [2021,2022,2023,2044]
                option.series[1].data = [2025,2024,2043,2324]
                option.series[2].data = [2023,2222,2022,2824]

                // 数据准备完毕之后再set
                myChart.setOption(option);


                pieoptions.series[0].data =  [
                    {name: "第一季度", value: 1},
                    {name: "第二季度", value: 2},
                    {name: "第三季度", value: 3},
                    {name: "第四季度", value: 4},
                ]
                pieoptions.title.text = ["{value|" + 99 + "}", "{name|总数}"].join("\n")
                pieChart.setOption(pieoptions)
            })
        }
    }
</script>

<style scoped>

</style>
